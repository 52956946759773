import classNames from "classnames"
import React from "react"
import PropTypes from "prop-types"
import IdeasLogo from "./IdeasLogo"
import Logo from "./Logo"

import styles from "../../styles/header.module.styl"

const Header = ({ children, className }) => {
  const headerClassess = classNames(styles.header, className)

  return (
    <div className={headerClassess}>
      <div className={styles.rowWithLogos}>
        <IdeasLogo />
      </div>
      <div className={styles.headerContent}>{children}</div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  className: "",
  children: null,
}

export default Header
