import _ from "lodash"
import Immutable from "immutable"
import React from "react"
import { kebabCase } from "lodash"
import ReactDOMServer from "react-dom/server"
import { DefaultDraftInlineStyle, DefaultDraftBlockRenderMap } from "draft-js"

const CUSTOM_STYLE_MAP = {
  SMALL_FONT: {
    fontSize: "10px",
  },
  MODERATE_FONT: {
    fontSize: "12px",
  },
  NORMAL_FONT: {
    fontSize: "14px",
  },
  LARGE_FONT: {
    fontSize: "18px",
  },
  HUGE_FONT: {
    fontSize: "32px",
  },
  HIGHLIGHT: {
    backgroundColor: "#FFDDAA",
  },
}

function getBlockRenderMap(customEntries) {
  var additionalEntries
  additionalEntries = {
    section: {
      element: "section",
    },
  }
  return DefaultDraftBlockRenderMap.merge(
    Immutable.Map(_.extend(additionalEntries, customEntries))
  )
}

const defaultDecorators = {
  LINK: function(props) {
    return React.createElement("a", {
      href: props.data.get("url"),
      dangerouslySetInnerHTML: {
        __html: props.children,
      },
    })
  },
}

const inlineStylesMap = _.extend({}, DefaultDraftInlineStyle, CUSTOM_STYLE_MAP)

const blockStylesMap = {
  TEXT_ALIGN_LEFT: {
    textAlign: "left",
  },
  TEXT_ALIGN_CENTER: {
    textAlign: "center",
  },
  TEXT_ALIGN_RIGHT: {
    textAlign: "right",
  },
}

function getStyledCharsMap(styleRanges) {
  return styleRanges
    .map(function(rangeSpec) {
      var length, offset, style
      ;({ offset, length, style } = rangeSpec.toJS())
      return Immutable.OrderedMap(
        Immutable.Range(offset, offset + length).zip(
          Immutable.Repeat(Immutable.List([style]), length)
        )
      )
    })
    .reduce(function(acc, inlineStylesMap) {
      return acc.mergeWith(function(prev, next) {
        return prev.concat(next)
      }, inlineStylesMap)
    }, Immutable.OrderedMap())
}

function getEntityCharsMap(entityRanges) {
  return entityRanges.reduce(function(resultingMap, rangeSpec) {
    var key, length, offset
    ;({ offset, length, key } = rangeSpec.toJS())
    return resultingMap.merge(
      Immutable.OrderedMap(
        Immutable.Range(offset, offset + length).zip(
          Immutable.Repeat(key, length)
        )
      )
    )
  }, Immutable.OrderedMap())
}

function stylesToString(styles) {
  return styles.reduce(function(result, style) {
    var styleRule, value
    styleRule = inlineStylesMap[style] || blockStylesMap[style]
    if (styleRule == null) {
      return result
    }
    ;[style, value] = _.toPairs(styleRule)[0]
    return result.concat(`${kebabCase(style)}:${value};`)
  }, "")
}

function textFragmentToHTML(textFragment) {
  var styles, text
  text = textFragment.get("text")
  styles = textFragment.get("style")
  return getHTMLBlock(
    "span",
    text,
    !styles.isEmpty()
      ? {
          style: stylesToString(styles),
        }
      : void 0
  )
}

function getBlockFragments(text, styleRanges, entityRanges) {
  var entityCharsMap, styledCharsMap
  if (_.isEmpty(text)) {
    return Immutable.List()
  }
  // convert List of style ranges into OrderedMap of type `charIndex -> List inlineStyle`
  styledCharsMap = getStyledCharsMap(styleRanges)
  // convert List of entity ranges into OrderedMap of type `charIndex -> entityKey`
  entityCharsMap = getEntityCharsMap(entityRanges)
  // covert raw text string into List of textFragment specs
  return Immutable.List(text).reduce(function(fragments, char, charIdx) {
    var charEntityKey,
      charStyles,
      prevCharEntityKey,
      prevCharStyles,
      updatedLastFragment
    charStyles = styledCharsMap.get(charIdx, Immutable.List())
    prevCharStyles = styledCharsMap.get(charIdx - 1, Immutable.List())
    charEntityKey = entityCharsMap.get(charIdx)
    prevCharEntityKey = entityCharsMap.get(charIdx - 1)
    // if char has the same style and entity key as the previous one, then just concat it to the
    // latest textFragment
    if (
      charStyles.equals(prevCharStyles) &&
      charEntityKey === prevCharEntityKey &&
      charIdx !== 0
    ) {
      updatedLastFragment = fragments
        .last()
        .update("textFragments", function(textFragments) {
          return textFragments.set(
            textFragments.size - 1,
            textFragments.last().update("text", function(text) {
              return text.concat(char)
            })
          )
        })
      return fragments.set(fragments.size - 1, updatedLastFragment)
      // if char has same entity key as the previous one - then create new textFragment
    } else if (
      charEntityKey != null &&
      charEntityKey === prevCharEntityKey &&
      charIdx !== 0
    ) {
      updatedLastFragment = fragments
        .last()
        .update("textFragments", function(textFragments) {
          return textFragments.push(
            Immutable.Map({
              text: char,
              style: charStyles,
            })
          )
        })
      return fragments.set(fragments.size - 1, updatedLastFragment)
    } else {
      // otherwise create a new block fragment
      return fragments.push(
        Immutable.Map({
          textFragments: Immutable.List([
            Immutable.Map({
              text: char,
              style: charStyles,
            }),
          ]),
          entityKey: charEntityKey,
        })
      )
    }
  }, Immutable.List())
}

function decorateText(entityData, decorator, text) {
  return ReactDOMServer.renderToStaticMarkup(
    React.createElement(
      decorator,
      _.extend({
        data: entityData,
        children: text,
      })
    )
  )
}

function contentBlockToHTML(entityMap, entityDecorators) {
  return function(block, idx, blocks) {
    var blockFragments,
      blockHTML,
      blockInnerHTML,
      blockStyles,
      blockStylesString,
      entityRanges,
      nextBlockType,
      prevBlockType,
      styleRanges,
      tag,
      text,
      type,
      wrapTag,
      wrapper
    type = block.get("type")
    text = block.get("text")
    styleRanges = block.get("inlineStyleRanges")
    entityRanges = block.get("entityRanges")
    ;({ element: tag, wrapper } = getBlockRenderMap().get(type))
    if (tag == null) {
      // skip unknown type
      return ""
    }
    // analyze entity ranges and style ranges and prepare a List of block fragments of following type:
    //   List Map {
    //     textFragments: List Map { text: String, style: List String }
    //     entityKey: Number
    //   })
    blockStyles = block.getIn(["data", "styles"], Immutable.List())
    blockFragments = getBlockFragments(text, styleRanges, entityRanges)
    // 'render' block fragments and join together to get single HTML string
    blockInnerHTML = blockFragments
      .map(function(blockFragment) {
        var decorator,
          entity,
          entityData,
          entityKey,
          entityType,
          textFragments,
          textFragmentsHTML
        textFragments = blockFragment.get("textFragments")
        entityKey = blockFragment.get("entityKey")
        textFragmentsHTML = textFragments.map(textFragmentToHTML).join("")
        if (entityKey == null) {
          return textFragmentsHTML
        }
        // now wrap text fragments in decorator component
        entity = entityMap.get(`${entityKey}`)
        entityType = entity.get("type")
        decorator = entityDecorators[entityType]
        if (decorator == null) {
          return textFragmentsHTML
        }
        entityData = entity.get("data")
        return decorateText(entityData, decorator, textFragmentsHTML)
      })
      .join("")
    blockStylesString = stylesToString(blockStyles)
    blockHTML = getHTMLBlock(
      tag,
      blockInnerHTML,
      blockStylesString
        ? {
            style: blockStylesString,
          }
        : void 0
    )
    // some block elements like <li /> may have wrapper, e.g. <ul />
    if (wrapper != null) {
      prevBlockType = blocks.getIn([idx - 1, "type"])
      nextBlockType = blocks.getIn([idx + 1, "type"])
      wrapTag = wrapper.type
      // prepend opening wrapperTag to if this is the first occurrence of such item
      if (prevBlockType !== type) {
        blockHTML = otag(wrapTag).concat(blockHTML)
      }
      // append closing wrapperTag to if this is the last occurrence of such item
      if (nextBlockType !== type) {
        blockHTML = blockHTML.concat(ctag(wrapTag))
      }
    }
    return blockHTML
  }
}

function otag(tag, attrs) {
  var attr, attrVal, tagStr
  tagStr = `<${tag}`
  if (attrs != null && _.isObject(attrs)) {
    for (attr in attrs) {
      attrVal = attrs[attr]
      tagStr = `${tagStr} ${attr}="${attrVal}"`
    }
  }
  return (tagStr += ">")
}

function ctag(tag) {
  return `</${tag}>`
}

function getHTMLBlock(tag, text, attrs) {
  return otag(tag, attrs).concat(text, ctag(tag))
}

export function rawContentToHTML(rawContent, customDecorators) {
  var contentHTML, decorators, entityMap, rawContentBlocks
  if (!rawContent) {
    return ""
  }
  decorators = _.extend({}, defaultDecorators, customDecorators)
  if (rawContent.toJS == null) {
    // ensure rawContent is wrapped in Immutable container
    rawContent = Immutable.fromJS(rawContent)
  }
  rawContentBlocks = rawContent.get("blocks")
  entityMap = rawContent.get("entityMap")
  contentHTML = rawContentBlocks
    .map(contentBlockToHTML(entityMap, decorators))
    .join("")
  return getHTMLBlock("div", contentHTML)
}
