import Link from "gatsby-link"
import React from "react"

import logo from "../../assets/svg/ideas-logo.svg"
import styles from "../../styles/header.module.styl"

export default () => (
  <Link className={styles.logoLink} to="/vte">
    <img className={styles.logo} src={logo} alt="logo" />
  </Link>
)
