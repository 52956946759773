import classNames from "classnames"
import Immutable from "immutable"
import React from "react"
import { oneOfType, shape, string, array, object, instanceOf } from "prop-types"
import { isJsonString } from "../../lib/utils"
import styles from "../../styles/textContent.module.styl"
import { rawContentToHTML } from "../../lib/draft_utils"

const TextContent = ({ content, className }) => {
  const editorContent = isJsonString(content)
    ? rawContentToHTML(JSON.parse(content))
    : content

  return (
    <div className={classNames(styles.textEditor, className)}>
      <div
        dangerouslySetInnerHTML={{
          __html: editorContent,
        }}
      />
    </div>
  )
}

TextContent.propTypes = {
  content: oneOfType([
    string,
    shape({
      blocks: array,
      entityMap: object,
    }),
    instanceOf(Immutable.Map),
  ]).isRequired,
  className: string,
}

TextContent.defaultProps = {
  className: "",
}

export default TextContent
